import React from 'react';

import Layout from '../components/Layout';
import SEO from "../components/Seo"
import pic from '../assets/images/05.jpg';

const IndexPage = () => (
  <Layout>
  <SEO title="Headless" description="The woodsman's greatest horror isn't the beast itself but the reactions of those around him." />
    <div id="main">
    <div className="inner">
    <header>
    <div className="resumeTitle">
  <h1>Headless</h1><h4>311 words | Horror</h4>
  </div>
    </header>
      <section>
        <div className="box alt">
						<div className="row gtr-uniform">
							<div className="col-12"><span className="image fit"><img src={pic} alt="" /></span></div>
						</div>
          </div>
        <div className="storyText">
          <p>It was no hyperbole to call the beast headless. Platitudes may have led many to chuckle and sway as I told my tale, but of all the days to shake them from their monotonous drivel, it had to be when death was just a short ride into the woodlands.</p>
          <p>"Perhaps its head was ducked and covered by vines and brush. With the way you hunt, Tammac? It'd be sure to cower if anything."</p>
          <p>Before the drunkard and his comrades had a single moment to hoot and holler — dismissing my greatest fears — my voice filled with venom, "I am not mistaken. And despite the sweat running down my neck and the blood flush on my cheeks, I assure you I am not mad. What I found out in those wilds was indeed headless."</p>
          <p>"It wasn't-"</p>
          <p>"Blood seeped from the cavern between its shoulders."</p>
          <p>"But surely it couldn't-"</p>
          <p>"The beast leapt towards me: brandish nine razor sharp claws as it barreled through bushes and oaks indiscriminately."</p>
          <p>"And you're sure-""I haven't a spot of drink on my breath and no pipe to my name. The fiend I speak of is real."</p>
          <p>Silence spread through the tavern. Not one man sipped his tankard nor scratched his scraggly beard. At long last, I believed I had been heard. The threat in the wilds — the headless beast that stalked our forests — would not be satisfied roaming the wealds unfed. We needed the realms strongest guardsman. No. We needed fire. Pity would it be to burn the lands that gave us lumber and food, but if it was to stop that menace...</p>
          <p>The drunkard looked up at me — eyes blood shot and lips chapped. If he only felt a sliver of the same fear I felt, I'd have known I had done right by my homeland. But instead, the man said, "Well, better it's there than here."</p>
        </div>
      </section>
    </div>
    </div>
  </Layout>
);

export default IndexPage;
